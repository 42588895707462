// extracted by mini-css-extract-plugin
export var container = "ProfileCustomerSubscriptions-module--container--1E7m1";
export var content = "ProfileCustomerSubscriptions-module--content--15ocr";
export var title = "ProfileCustomerSubscriptions-module--title--a6YdE";
export var goBackIcon = "ProfileCustomerSubscriptions-module--goBackIcon--27Zc3";
export var box = "ProfileCustomerSubscriptions-module--box--18mgd";
export var currentlyMembership = "ProfileCustomerSubscriptions-module--currentlyMembership--_1U8R";
export var availableUpgrade = "ProfileCustomerSubscriptions-module--availableUpgrade--kkELD";
export var membershipStatus = "ProfileCustomerSubscriptions-module--membershipStatus--2BagW";
export var freeMembership = "ProfileCustomerSubscriptions-module--freeMembership--_7UBj";
export var vipMembership = "ProfileCustomerSubscriptions-module--vipMembership--3rka6";
export var statusContainer = "ProfileCustomerSubscriptions-module--statusContainer--28k9s";
export var checkBlueIcon = "ProfileCustomerSubscriptions-module--checkBlueIcon--3e4uW";
export var statusText = "ProfileCustomerSubscriptions-module--statusText--3U8fk";
export var statusInactive = "ProfileCustomerSubscriptions-module--statusInactive--1bFLj";
export var button = "ProfileCustomerSubscriptions-module--button--1zhQI";
export var deactivateButton = "ProfileCustomerSubscriptions-module--deactivateButton--1BjSV";
export var info = "ProfileCustomerSubscriptions-module--info--5S6pm";
export var titleRow = "ProfileCustomerSubscriptions-module--titleRow--2zcX7";
export var infoTitle = "ProfileCustomerSubscriptions-module--infoTitle--2gkH6";
export var memberSince = "ProfileCustomerSubscriptions-module--memberSince--3Ao-u";
export var card = "ProfileCustomerSubscriptions-module--card--3gp03";
export var cardTitle = "ProfileCustomerSubscriptions-module--cardTitle--2gK0-";
export var cardInfo = "ProfileCustomerSubscriptions-module--cardInfo--2fd0B";
export var cardDate = "ProfileCustomerSubscriptions-module--cardDate--1_voe";
export var cardSum = "ProfileCustomerSubscriptions-module--cardSum--3A7ps";
export var footerContainer = "ProfileCustomerSubscriptions-module--footerContainer--3U14w";