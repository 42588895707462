import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { convertToUSD, trackingGA } from '@merchx-v2/helpers'

import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Notification, SEO } from 'components'
import goBackIcon from 'assets/goBack.svg'
import checkBlueIcon from 'assets/checkBlue.svg'
import * as styles from './ProfileCustomerSubscriptions.module.scss'

import { actionCreators as actionHomeCreators, selectMenu } from 'state/HomePage/reducer'
import { selectors } from 'state/LoginPage/reducer'
import * as actions from 'state/LoginPage/actions'

const membershipStatusesForReactivation = ['EXPIRING', 'EXPIRED']
const membershipStatusesForDeactivation = ['TRIAL', 'ACTIVE']

const ProfileCustomerSubscriptions = ({
  loginState,
  menu,

  customerAccountDeactivateSubscription,
  customerAccountReactivateSubscription,
  resetMenuFilters,
  setMenuCollection
}) => {
  const [notificationTitle, setNotificationTitle] = useState('Error')
  const [notification, showNotification] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken')

  if (!refreshToken) {
    return null
  }

  const handleReactivate = async e => {
    e.preventDefault()

    const isChanged = await customerAccountReactivateSubscription()
    if (isChanged.payload) {
      navigate('/profile/confirm-reactivation')
    } else {
      setNotificationTitle('Error')
      showNotification(isChanged.error.message)
    }
  }

  const formatDateHandler = date => {
    const myDate = new Date(date)
    const month = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sep.', 'Oct.', 'Nov', 'Dec.'][myDate.getMonth()]
    const year = myDate.getFullYear()
    const day = myDate.getDate()
    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
  }

  return (
    <div className={styles.container}>
      <SEO />
      <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} isNeedDescription={true} />
      <div className={styles.content}>
        <div className={styles.title}>
          <img className={styles.goBackIcon} src={goBackIcon} alt='<' onClick={() => navigate('/profile')} />
          Subscriptions
        </div>

        <div className={styles.box}>
          <p className={styles.currentlyMembership}>Currently member of:</p>
          <div className={styles.membershipStatus}>
            <p className={styles.freeMembership}>{loginState.membershipInfo?.status === 'ACTIVE' || 'EXPIRING' ? 'VIP Membership' : 'Free Membership'}</p>
            <div className={styles.statusContainer}>
              <img className={styles.checkBlueIcon} src={checkBlueIcon} alt='&check;' />
              <p className={styles.statusText}>Active</p>
            </div>
          </div>
          {membershipStatusesForDeactivation.includes(loginState.membershipInfo?.status) && (
            <button className={styles.deactivateButton} onClick={() => navigate('/profile/deactivate-subscription')}>
              Deactivate Membership
            </button>
          )}
          {membershipStatusesForReactivation.includes(loginState.membershipInfo?.status) && (
            <>
              <p className={styles.availableUpgrade}>Available upgrade:</p>
              <div className={styles.membershipStatus}>
                <p className={styles.vipMembership}>Vip membership</p>

                <p className={styles.statusInactive}>Inactive</p>
              </div>
              <div className={styles.button}>
                <Button value='Reactivate Membership' customAction={handleReactivate} className='buttonSmall' />
              </div>
            </>
          )}
        </div>

        <div className={styles.info}>
          <div className={styles.titleRow}>
            <div className={styles.infoTitle}>Recent Billing</div>
            <div className={styles.memberSince}>Member Since {new Date(loginState.membershipInfo?.activeFrom).getFullYear()}</div>
          </div>

          {loginState.membershipInfo.recentPayments.length
            ? loginState.membershipInfo.recentPayments.map(item => {
                return (
                  <div className={styles.card}>
                    <p className={styles.cardTitle}>VIP Membership</p>
                    <div className={styles.cardInfo}>
                      <p className={styles.cardDate}>{formatDateHandler(item.createdAt)}</p>
                      <p className={styles.cardSum}>Paid ${convertToUSD(item.total)}</p>
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification
          title={notificationTitle}
          message={notification}
          onNotificationClosed={() => {
            setNotificationTitle('Error')
            showNotification('')
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  loginState: selectors.selectLoginState(state)
})

const mapDispatchToProps = {
  customerAccountDeactivateSubscription: actions.customerAccountDeactivateSubscription,
  customerAccountReactivateSubscription: actions.customerAccountReactivateSubscription,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCustomerSubscriptions)
